@import "~@/assets/sass/_function";

.CustomSearchInput_fzOffCH {
  .ant-input-suffix,
  .ant-input-prefix {
    .suffix_fzOffCH {
      .ic {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: $font_gy_3;
      }
    }
  }
}

.CustomSearchInput2_fzOffCH {
  &.ant-input-affix-wrapper {
    padding-right: 4px;
  }
  .ant-input-prefix {
    .ic {
      color: $font_gy_3;
    }
  }
}
