@import "~@/assets/sass/_function";

.FilePiker {
  .upload-wrap {
    .upload {
      min-width: 80px;
      height: 90px;

      &-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        @extend %f12;
        background: $white;
        border: 1px dashed #dedede;
        cursor: pointer;
        color: $contrast-color;
      }
      &-item {
        &-image {
          position: relative;
          height: 90px;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }

          &:hover {
            &::after,
            .upload-item-action {
              opacity: 1;
              transition: all 0.3s ease-in-out;
            }
          }
        }
        &-action {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
          opacity: 0;
          z-index: 1;

          .action-icon {
            margin: 0 5px;
          }
        }
      }
      &-placeholder {
        margin-left: 28px;
      }
    }
  }
}
