@import "~@/assets/sass/_function";

.ModalBase {
  .ant-modal-header {
    @extend %f16;
    color: $text-color-gray;
    background-color: $popup-bg;
  }
  .ant-modal-content {
    padding: 20px;
    border-radius: 8px;
  }
  .ant-modal-footer {
    text-align: center;
    background-color: $popup-bg;
  }
}
