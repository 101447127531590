@import "~@/assets/sass/_function";

.QEditor {
  display: flex;
  flex-direction: column;

  .ql-container {
    flex: 1;
    overflow-y: auto;
  }

  @for $i from 0 through 6 {
    @if $i == 0 {
      .ql-snow .ql-picker.ql-header .ql-picker-label::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: "正文";
      }
    } @else {
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="#{$i}"]::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="#{$i}"]::before {
        content: "标题#{$i}";
      }
    }
  }

  // size
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
  }
}
