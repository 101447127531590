@import "@/assets/sass/function";

.ant-modal-confirm {
  .ant-modal-content {
    padding: 24px;
  }
  .ant-modal-confirm-body {
    padding-bottom: 16px;
    & > .ic {
      margin-right: 12px;
      float: left;
      width: 24px;
      height: 24px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $colorPrimary;
    }
    .ant-modal-confirm-title {
      margin-right: 24px;
      min-height: 24px;
      font-size: 16px;
      font-weight: var(--fw500);
      line-height: 24px;
      color: $font_gy_1;
    }
    .ant-modal-confirm-content {
      // margin-left: 38px;
      color: $font_gy_2;
    }
  }

  .ant-modal-confirm-btns {
    .ant-btn {
      min-width: 88px;
    }
  }
}
.ant-modal-confirm-title {
  .title-inner {
    display: flex;
    align-items: center;
    font-weight: $fw500;
    color: $font_gy_1;
    .ic {
      font-weight: normal;
      font-size: 20px;
      color: $brand_7;
      margin-right: 6px;
    }
  }
}

// 中等边距样式 .middleEdge
// https://oss.5rs.me/oss/uploadfe/png/1fd8b548842b1558ed156fba63200452.png
.ant-modal.middleEdge {
  .ant-modal-header {
    height: 56px;
    padding: 16px;
    border-bottom: 1px solid $gray3;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    /* Text&Icon/Font Gy1 90%-primary */
    color: $font_gy_1;
  }
  .ant-modal-footer {
    padding: 24px 24px;
    .ant-btn {
      min-width: 88px;
    }
  }
  .ant-modal-close-x {
    top: 16px;
    right: 16px;
  }
}

// 删除确认样式 .DeleteConfirm_YbbQof55
// https://oss.5rs.me/oss/uploadfe/png/7081df893e5282d0d1b5aaa28bacf2ee.png
.ant-modal-confirm.DeleteConfirm_YbbQof55 {
  .ant-modal-close-x {
    top: 24px;
    right: 24px;
  }
  .title-inner .ic {
    // color: $error_6;
  }
  .ant-modal-confirm-btns {
    .ant-btn {
      min-width: 88px;
    }
  }
}
