@import "~@/assets/sass/_function";

.ant-select {
  .ant-select-arrow {
    &::after {
      transition: all 0.3s linear;
    }
  }
}

.ant-select-open {
  .ant-select-arrow {
    &::after {
      transform: rotateZ(180deg);
    }
  }
}
