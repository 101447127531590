@import "~@/assets/sass/_function";

.Image {
  height: 100%;
  .ant-image {
    vertical-align: bottom;
    &-img {
      border-radius: $radiusBase;
      object-fit: contain;
    }
  }
}
