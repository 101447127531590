@charset "UTF-8";

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$font-size: 14px !default;
$pad-font-size: 12px !default;
$font-line-height: 1.5 !default;
$font-family:
  San Francisco UI,
  Helvetica Neue,
  Arial,
  DIN Alternate,
  PingFang SC,
  Hiragino Sans GB,
  Micrisoft YaHei !default;

// color
$primary-color: #5156be !default; // 主色
$primary-color-light: #87abdc !default; // hover
$primary-color-hover: #666ceb !default; // 悬浮
$primary-color-active: #414598 !default; // 点击
$primary-color-disabled: #b1b4ed !default; //禁用
$primary-color-focus: #d9e1ff !default;
$secondary-color: #3875c6 !default; // 辅色 ｜ 链接
$contrast-color: #04ba97 !default; // 对比色 | 成功
$warning-color: #f53f3f !default; //警示颜色 ｜ 失败
$default-text-color: #192d51 !default; // 表格文本颜色
$primary-color-border: #f2f3ff;
$primary-color-line: #d9d9d9; //分割线
$primary-color-table-border: #dcdcdc; //表格边框
$primary-color-table-bg: #f5f7fa; //表头背景
$primary-color-table-close: #f9f9f9; //表格禁用项
// 主色 4%
$primary-color-4: #f8f8fd;
// 主色 8%
$primary-color-8: #f1f2fa;
// 主色 12%
$primary-color-12: rgba(81, 86, 190, 0.12);

$info-color: #598ae3 !default; // 蓝色
$tips-color: #ffa246 !default; //提示颜色
$link-color: #666 $primary-color !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$bg-color: #f7faff !default;
$bg-contentColor: #f6f7f9; //内容色
$bg-disableColor: #f1f2f6; //禁用色
$bg-icColor: #999fb6; //图标色
$line-color: #e4e8ee; //分割线
$stroke-color: #dadee5; //描边
$placeholder-color: #b3b0ba !default;
$white: white !default;
$black: black !default;
$colorPrimary: $primary-color; // for wtdc components
$colorPrimaryLight: #87abdc; // for wtdc components 指向色

// base values
$marginBase: 20px !default;
$paddingBase: 20px !default;
$paddingContent: 24px !default;

// 错误
$error-color: #fa5652;
$error-color-light: #fff2f2;
$error-color-hover: #e64f4c;
$error-color-active: #ad352f;
$error-color-disabled: #ffb9b0;

// 警告
$warning-color: #ffa100;
$warning-color-light: #fff8eb;
$warning-color-hover: #fa9550;
$warning-color-active: #be5a00;
$warning-color-disabled: #ffb98c;

// 成功
$success-color: #2ab57d;
$success-color-light: #eef9f5;
$success-color-hover: #56c08d;
$success-color-active: #008858;
$success-color-disabled: #92dab2;

// font color
$font_gy_1: rgba(0, 0, 0, 0.9); // Primary
$font_gy_2: rgba(0, 0, 0, 0.6); // Secondary
$font_gy_3: rgba(0, 0, 0, 0.4); // Placeholder
$font_gy_4: rgba(0, 0, 0, 0.26); // Disabled
$font_gy_5: rgba(0, 0, 0, 0.1);
$font_wh_1: rgba(255, 255, 255, 0.9); // Anti
$font_wh_2: rgba(255, 255, 255, 0.55);
$font_wh_3: rgba(255, 255, 255, 0.35);
$font_wh_4: rgba(255, 255, 255, 0.22);

// 中性色 neutral color
$neutral_color_1: #dcdcdc; // 中性色1：#DCDCDC（滚动条、输入框边框色、不可用色）
$neutral_color_2: #eeeeee; // 中性色2：#EEEEEE（页面容器、表格边框）
$neutral_color_3: #f3f3f3; // 中性色3：#F3F3F3（下拉筛选等Hover状态）
$neutral_color_4: #f9f9f9; // 中性色4：#F9F9F9（大面积使用的背景色）
$neutral_color_5: #f5f7fa; // 中性色5：#F5F7FA（表格表头）

// text color
$text-color: #333333 !default;
$text-color-dark: #8f959e !default;
$text-color-light: #192d51 !default;
$text-color-gray: #dcdcdc !default;
$tword-color: #192d51; //正文字体颜色
$text-color-placeholder: $font_gy_3; //占位符
$text-color-placeholder-anti: $font_wh_3; // 占位符反色
$text-color-disabled: $font_gy_4; //禁用
$text-color-disabled-anti: $font_wh_4; // 禁用反色
$text-color-link: $primary-color; //链接色
$test-step-color: #c1c4d4;
$text-color-anti: $font_wh_1; //主色反色
$text-color-secondary: $font_gy_2; // 辅色

$text-color-8: rgba($text-color-dark, 0.8);
$text-color-6: rgba($text-color-dark, 0.6);
$text-color-4: rgba($text-color-dark, 0.4);
$text-color-2: rgba($text-color-dark, 0.2);

//容器色
$bg-color: #f8f8fd !default; //背景遮罩色
$bg-color-container: #ffffff; //主要容器
$bg-color-container-hover: #f3f3f3;
$bg-color-container-active: #e7e7e7;

$bg-color-secondarycontainer: #f3f3f3; //次要容器
$bg-color-secondarycontainer-hover: #eeeeee; //次要容器
$bg-color-secondarycontainer-active: #dcdcdc; //次要容器

$bg-color-component: #d9e1ff; //组件背景色
$bg-color-component-hover: #dcdcdc; //组件背景色
$bg-color-component-active: #a6a6a6; //组件背景色
$bg-color-component-disabled: #eeeeee; //组件背景色

// border color
$border-color: #ddd !default;

//  border radius
$radiusSmall: 4px !default;
$radiusBase: 6px !default;

// bg color
$page-gray-bg: #f1f2f6 !default;

// 弹窗头部背景颜色
$popup-bg: #f4f6f9;
// form
$inputWidth: 256px !default;

// media
$tablet-width: 1024px;
$laptop-width: 1280px;
$desktop-width: 1920px;

// base values
$margin-base: 20px !default;
$margin: 16px !default;
$padding-base: 20px !default;
$padding: 16px !default;
$margin-gap: 8px !default;
$padding-content: 24px !default;
// zIndex
$headerIndex: 100;

$fw500: var(--fw500);
$fw600: var(--fw600);
// 主色 4%
$primary-color-4: #f8f8fd;
// 主色 8%
$primary-color-8: #f1f2fa;
// 主色 12%
$primary-color-12: rgba(81, 86, 190, 0.12);

$colorPrimary: $primary-color; // for wtdc components
$colorPrimaryLight: $primary-color-light; // for wtdc components 指向色

// 错误
$error-color: #fa5652;
$error-color-light: #fff2f2;
$error-color-hover: #e64f4c;
$error-color-active: #ad352f;
$error-color-disabled: #ffb9b0;

// 警告
$warning-color: #ffa100;
$warning-color-light: #fff8eb;
$warning-color-hover: #fa9550;
$warning-color-active: #be5a00;
$warning-color-disabled: #ffb98c;

// 成功
$success-color: #2ab57d;
$success-color-light: #eef9f5;
$success-color-hover: #56c08d;
$success-color-active: #008858;
$success-color-disabled: #92dab2;

// font color
$font_gy_1: rgba(0, 0, 0, 0.9); // Primary
$font_gy_2: rgba(0, 0, 0, 0.6); // Secondary
$font_gy_3: rgba(0, 0, 0, 0.4); // Placeholder
$font_gy_4: rgba(0, 0, 0, 0.26); // Disabled
$font_gy_5: rgba(0, 0, 0, 0.1);
$font_wh_1: rgba(255, 255, 255, 0.9); // Anti
$font_wh_2: rgba(255, 255, 255, 0.55);
$font_wh_3: rgba(255, 255, 255, 0.35);
$font_wh_4: rgba(255, 255, 255, 0.22);

// 中性色 neutral color
$neutral_color_1: #dcdcdc; // 中性色1：#DCDCDC（滚动条、输入框边框色、不可用色）
$neutral_color_2: #eeeeee; // 中性色2：#EEEEEE（页面容器、表格边框）
$neutral_color_3: #f3f3f3; // 中性色3：#F3F3F3（下拉筛选等Hover状态）
$neutral_color_4: #f9f9f9; // 中性色4：#F9F9F9（大面积使用的背景色）
$neutral_color_5: #f5f7fa; // 中性色5：#F5F7FA（表格表头）

//文本色
$text-color: $font_gy_1; //主色
$text-color-anti: $font_wh_1; //主色反色
$text-color-secondary: $font_gy_2; // 辅色
$text-color-secondary-anti: $font_wh_2; // 辅色反色
$text-color-placeholder: $font_gy_3; //占位符
$text-color-placeholder-anti: $font_wh_3; // 占位符反色
$text-color-disabled: $font_gy_4; //禁用
$text-color-disabled-anti: $font_wh_4; // 禁用反色
$text-color-link: $primary-color; //链接色
$test-step-color: #c1c4d4;
$primary-color-focus: #d9e1ff !default;
$primary-color-hover: #666ceb !default; // 悬浮
$primary-color-active: #414598 !default; // 点击
$primary-color-disabled: #b1b4ed !default; //禁用

// 圆角类型
$radius-small: 4px !default; //小圆角
$radius-base: 6px;
$radius-large: 12px !default; //大圆角
// ------------ START: 后面尽量不要用这些常量 ------------
$gray1: $neutral_color_3;
$gray2: $neutral_color_2;
$gray3: #e7e7e7; //布局边框使用此值
$gray4: #dcdcdc; //列表边框使用此值
$gray5: #c5c5c5;
$gray6: #a6a6a6;
$gray9: #5e5e5e;
$gray17: #f7f7f7;
$gray19: #767676;
$gray22: $neutral_color_4;
$brand_1: $primary-color-light; // Light
$brand_2: $primary-color-focus; // FocuS
$brand_3: $primary-color-disabled; // Disabled
$brand_6: $primary-color-hover; // Hover
$brand_7: $primary-color; // Normal
$error_1: $error-color-light; // Light
$error_5: $error-color-hover; // Hover
$error_6: $error-color; // Normal
$success_4: $success-color-hover; // Hover
$success_5: $success-color; // Normal
// Brand 品牌/Brand-color-list bg-close
$bg_close: $neutral_color_4;
// 兼容“知识商城”样式
$gray_3: #333;
// ------------ END ------------
